.wsCardContainer {
    height: 100%;
    max-height: 600px;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 0.6rem;
    padding: 10px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.cardRow {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);
    border-radius: 0.6rem;
    min-height: 4rem;
    margin: 0;
    margin-bottom: 1rem;
    cursor: pointer;
    z-index: 1;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: space-between;
}

.renameIcon {
    margin-right: 10px;
}

.deleteIcon {
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.btn_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}
