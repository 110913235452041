.formContainer {
    max-width: 32rem;
    /* max-height: 25rem; */
    /* overflow: auto; */
}
.MuiDialog-root {
    z-index: 99;
}
button#branchSelectionDisable {
    background-color: #f0f0f0;
    color: #6d6d6d;
}
#overflowHide .MuiDialogContent-root {
    overflow: hidden;
}
#selectStorage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18rem !important;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    height: 40px;
    border-radius: 10px;
    box-shadow: none;
}
#changeStorage {
    width: 152px;
    height: 36px;
    background-color: #fff;
    gap: 8px;
    border-radius: 4px;
    border: 1px;

    font-weight: 600;
    border: 1px solid #d7deea;
}
