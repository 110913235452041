.home-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem;
}
.dismiss_value {
    margin-top: -2px;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.46000000834465027px;
    text-align: left;
}
.home-top {
    /* flex-basis: 40%; */
    display: flex;
    flex-direction: column;
}
.getting-started {
    background: #fff;
    box-shadow: 1px 1px 20px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 8px 0px 18px 0px;
    margin-bottom: 1%;
    /* flex-basis: 80%; */
}
.number-data {
    display: flex;
    margin-bottom: 1%;
    /* flex-basis: 20%; */
}
.number-data-left {
    display: flex;
    justify-content: space-between;
    flex-basis: 50%;
    margin-right: 0.5%;
}
.number-data-right {
    display: flex;
    justify-content: space-between;
    flex-basis: 50%;
    margin-left: 0.5%;
}
.alertDiv {
    margin-bottom: 1.5rem;
    background: #fff;
    padding: 0px !important;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
}
.resourceContent {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}
.resourceTitle {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}
.home-bottom {
    flex-basis: 100%;
    display: flex;
    margin: 0%;
    flex-direction: column;
    gap: 1%;
}
.home-screen-empty {
    display: flex;
    margin: 2% auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.home-screen-empty h2 {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    color: #1f2328;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.home-screen-empty image {
    padding: 30px;
}
.home-screen-empty h6 {
    padding-top: 12px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #afafaf;
    text-align: center;
}
.alert-home {
    display: flex;
    flex-direction: column;
    width: 45em;
    height: 100%;
}
.newChat {
    width: 553px;
    height: 280px;
    /* overflow-y: auto; */
    flex-basis: 100%;
    background: rgb(255, 255, 255);
}
.home-row {
    flex: 1;
    display: flex;
    gap: 1%;
}
.home-column {
    flex-basis: 50%;
    /* width: 10%; Remove this line */
}
.box {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 1px 1px 20px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.heading {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
#recentview-Container {
    width: 100%;
    height: 265px;
}
.invisibleScroller.resources {
    height: 310px;
}

.home-bottom {
    display: flex;

    justify-content: space-between;
}

.home-row {
    display: flex;
    justify-content: space-between;
}

.home-column {
    flex: 1;

    box-sizing: border-box;
}

/* Media query for screens up to 800px */
@media (max-width: 800px) {
    .home-column {
        width: 100%; /* Make each column take full width on smaller screens */
        margin-right: 0; /* Remove any right margin on columns */
        margin-bottom: 20px; /* Add some bottom margin between columns */
    }
}

@media (max-width: 800px) {
    .home-bottom {
        flex-direction: column;
    }

    .home-column {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
}
@media (min-width: 1364px) and (max-width: 1500px) {
    .alert-home {
        width: 36em;
    }
}
@media (min-width: 910px) and (max-width: 1091px) {
    .alert-home {
        width: 20em;
    }
    .newChat {
        width: 327px;
    }
    .cursor_pointer > .doCenter img {
        margin-left: -91px;
    }
    .invisibleScroller.resources {
        height: 225px;
    }
}
@media (min-width: 1092px) and (max-width: 1240px) {
    .alert-home {
        width: 25em;
    }
    .newChat {
        width: 420px;
    }
    .cursor_pointer > .doCenter img {
        margin-left: -91px;
    }
    .invisibleScroller.resources {
        height: 225px;
    }
}
@media (min-width: 1241px) and (max-width: 1367px) {
    .alert-home {
        width: 31em;
    }
    .newChat {
        width: 491px;
    }
}

@media (min-width: 1517px) and (max-width: 1820px) {
    .alert-home {
        width: 39em;
    }
    .newChat {
        width: 629px;
    }
}

@media (min-width: 1821px) and (max-width: 2051px) {
    .alert-home {
        width: 50em;
    }
    .newChat {
        width: 700px;
    }
}
/* 50% */
@media (min-width: 2052px) and (max-width: 2732px) {
    .alert-home {
        width: 75em;
    }
    .newChat {
        width: 1212px;
        margin-left: -14px;
        height: 330px;
    }
    .box {
        width: 100%;
        height: 396px;
    }
    .home-row {
        height: 44%;
    }
}

@media (min-width: 2733px) and (max-width: 4102px) {
    .alert-home {
        width: 114em;
    }
    .newChat {
        width: 1882px;
        height: 547px;
    }
    .box {
        height: 648px;
    }
    .home-row {
        height: 46%;
    }
    .home-screen-empty {
        margin: 13% auto;
    }
    .invisibleScroller.resources {
        height: 600px;
    }
}
text.MuiStepIcon-text.css-117w1su-MuiStepIcon-text {
    font-family: "Montserrat" !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    letter-spacing: -1px !important;
    text-align: center !important;
}
.step-btn {
    font-family: "Montserrat" !important;
    font-size: 13px;
    font-weight: 500 !important;
    line-height: 22px !important;
    letter-spacing: 0.46000000834465027px !important;
    text-align: left !important;
}
@media (min-width: 1200px) {
    .step-btn {
        width: 110px !important;
        font-size: 8.6px !important;
    }
}
@media (min-width: 1400px) {
    .step-btn {
        width: 133px !important;
        font-size: 11px !important;
    }
}
@media (min-width: 1600px) {
    .step-btn {
        width: 140px !important;
        font-size: 12px !important;
    }
}
@media (min-width: 1900px) {
    .step-btn {
        width: 150px !important;
        font-size: 13px !important;
    }
}
.MuiStepLabel-iconContainer > .Mui-completed {
    color: green !important;
}
