span.MuiTypography-root.MuiStepLabel-label.MuiStepLabel-active.MuiTypography-body2.MuiTypography-displayBlock,
span.MuiTypography-root.MuiStepLabel-label.MuiTypography-body2.MuiTypography-displayBlock {
    font-family: "Montserrat";
}

.formLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    /* or 214% */

    letter-spacing: 0.15px;

    /* Black / High Emphasis */

    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem;
    overflow-y: auto;
    max-height: 420px;
}

.modalRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

button#countType,
button#promiseStage {
    text-transform: capitalize;
}

.buttonStyle {
    color: black !important;
    border: 1px solid #b8b8b8 !important;
    width: 100%;
    max-height: 40px;
    border-radius: 10px !important;
    padding: 5px 8px !important;
}

.disabledButtonStyle {
    background-color: #f0f0f0 !important;
    color: #6d6d6d !important;
    border: 1px solid #b8b8b8 !important;
    width: 100%;
    max-height: 40px;
    border-radius: 10px !important;
    padding: 5px 8px !important;
}

.modalSubRow {
    display: flex;
    margin-bottom: 8px;
}

.newFolderD {
    box-sizing: border-box;
    position: absolute;
    width: 128px;
    height: 43px;
    background: #ffffff;
    border: 1px solid #a9a9a9 !important;
    border-radius: 10px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #a9a9a9 !important;
}

.newFolder {
    box-sizing: border-box;
    position: absolute;
    width: 128px;
    height: 43px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: black;
    background: #ffffff;
    border: 1px solid #000000 !important;
    border-radius: 10px !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.Mui-disabled.Mui-disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

#btn_addStep.Mui-disabled {
    background-color: #87b9e2 !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.newFolderD.Mui-disabled.Mui-disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

#flowModal {
    height: 485px;
}

.css-dozzat {
    height: 580px;
}

@media screen and (width: 1280px) {
    .css-dozzat {
        height: 525px;
    }

    #flowModal {
        height: 445px;
    }
}

/* 125% */
@media screen and (min-width: 1023px) and (max-width: 1162px) {
    .css-dozzat {
        height: 460px;
    }

    #flowModal {
        height: 380px;
    }
}

/* 110% */
@media screen and (min-width: 1163px) and (max-width: 1279px) {
    .css-dozzat {
        height: 520px;
    }

    #flowModal {
        height: 435px;
    }
}

/* 90% */
@media screen and (min-width: 1422px) {
    .css-dozzat {
        height: 640px;
    }

    #flowModal {
        height: 525px;
    }
}

/* 80% */
@media screen and (min-width: 1600px) {
    .css-dozzat {
        height: 720px;
    }

    #flowModal {
        height: 605px;
    }
}

@media screen and (width: 1440px) {
    .css-dozzat {
        height: 590px;
    }

    #flowModal {
        height: 500px;
    }
}

@media screen and (width: 1920px) {
    .css-dozzat {
        height: 835px;
    }

    #flowModal {
        height: 735px;
    }
}
