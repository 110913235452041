.top-section {
    display: flex;
    flex-direction: column;
    height: calc(100% - 175px);
    gap: 8px;
}
.top-section-static {
    display: flex;
    flex-direction: column;
}
.dynamic-tabs {
    height: calc(100% - 100px);
}
.top-section-dynamic {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 100px;
}
.bottom-section {
    margin-bottom: 8px;
}
.left-pane-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-wrap: nowrap;
}

.left-pane-item-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    margin-top: 9px;
    font-size: 10px;
    line-height: 12px;
    color: white;
}
.left-pane-icon-active {
    filter: invert(0%) sepia(93%) saturate(7492%) hue-rotate(134deg)
        brightness(87%) contrast(96%);
}
.ws-items {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    cursor: pointer;
}
.logo {
    background-image: url("../../../../../src/UC-logo-220px.svg");
}
@media only screen and (min-width: 700px) {
    .logo {
        background-image: url("../../../../../src/UC-logo-160px.svg");
        background-size: 170px;
    }
}
@media only screen and (min-width: 768px) {
    .logo {
        background-image: url("../../../../../src/UC-logo-160px.svg");
        background-size: 170px;
    }
}
@media only screen and (min-width: 800px) {
    .logo {
        background-image: url("../../../../../src/UC-logo-160px.svg");
        background-size: 120px;
    }
}
@media only screen and (min-width: 1000px) {
    .logo {
        background-image: url("../../../../../src/UC-logo-180px.svg");
        background-size: 160px;
    }
}
@media only screen and (min-width: 1200px) {
    .logo {
        background-image: url("../../../../../src/UC-logo-200px.svg");
        background-size: 170px;
    }
}

.titleSections {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 14px; */
    height: 10vh;
    top: 0px;
    position: sticky;
    /* z-index: -10; */
}

.mainPageLeftPaneBG.pl-0.pr-0 ul li {
    background-color: #27292c;
}
.mainPageLeftPaneBG.pl-0.pr-0 ul li span {
    color: white;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    line-height: 22.6px;
    letter-spacing: 0.3766483962535858px;
    text-align: left;
}
.list-group-item.active span {
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    line-height: 22.6px;
    letter-spacing: 0.3766483962535858px;
    text-align: left;

    color: black !important;
}
.ws-active {
    color: #000 !important;
    background-color: #d1efff !important;
}
