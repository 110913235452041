.dashboard-icons {
    padding-top: 2px;
}

.measure-block {
    display: flex;
    justify-content: center;
    align-items: center;
}
table.MuiTable-root.css-19edrg0-MuiTable-root {
    width: 1782px !important;
    height: 60px !important;
    grid-gap: 0px;
}

.summary-blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.summary-blocks span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: #000000;
}
.summary-blocks > .num {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */
    padding-top: 10px;
    display: flex;
    align-items: center;

    color: #000000;
}
.displayName {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    /* or 120% */

    display: flex;
    align-items: center;
    letter-spacing: 0.15008px;
    margin-left: 15px;
    color: #212529;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-paddingNone.MuiTableCell-sizeMedium.css-10dfkli-MuiTableCell-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #000000;
    padding-top: 12px;
}
tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root {
    font-family: "Montserrat";
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.instance-list-view {
    margin: auto;
    margin-top: 15%;
    width: 50%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    text-align: center;
}
#instance_table {
    height: 87%;
}
@media screen and (width: 1280px) {
    #instance_table {
        height: 87%;
    }
}

@media screen and (width: 1440px) {
    #instance_table {
        height: 90%;
    }
}

@media screen and (width: 1920px) {
    #instance_table {
        height: 96%;
    }
}
