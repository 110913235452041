div#add_Document_Modal {
    left: 250px;
    top: -30px;
}
#pg-viewer {
    height: 68vh !important;
    text-align: center
}
#react-doc-viewer {
    height: 68vh !important;
    padding: 30px;
    width: 100%;
    background: #fff;
    margin: auto;
    margin-bottom: 5px;
}