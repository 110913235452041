.form-controls input,
.form-controls.error input,
.form-controls.success input {
    border: 1px solid #9da5b4;
    padding: 6px 12px;
    border-radius: 4px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
.form-controls input:focus,
.form-controls.error input:focus,
.form-controls.success input:focus {
    border: 1px solid #0b5cd7;
    box-shadow: 0 0 0 3px #b6cff3;
    background-color: #eaeef5;
}
.form-controls input:hover {
    background-color: #f4f7fb;
    border: 1px solid #0b5cd7;
}
.form-controls.success input {
    border: 1px solid #197624;
}
.form-controls.error input {
    border: 1px solid #ff0000;
}
.form-controls.filled input {
    background-color: #f4f7fb;
}
input[type="text"]:disabled {
    color: #b7becd;

    font-size: 14px;
}

.form-controls {
    position: relative;
}

.input-container {
    height: 36px;
    position: relative;
}

.icon {
    position: absolute;
    left: 162px;
    top: 50%;
    transform: translateY(-50%);
}
