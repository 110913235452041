.mainRightBorder {
    height: 100vh;
    border-right: 1px solid #a9a9a9;
}

.cursurContextMenu {
    cursor: pointer;
}

.alignItemsToCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.alignLeftMenu {
    margin-left: 0px;
}

.NavItemPadding {
    padding-left: 15px;
}

.mainPageIconNewFont {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

span {
    font-family: "Montserrat";
}
.alert-time {
    position: absolute;
    padding: 8px;
    bottom: -55px;
    right: 0px;
    font-size: 10px;
    font-weight: 700;
    width: 14em;
}
.mainPageIconRadius {
    border-radius: 50%;
    width: 23px;
    height: 27px;
}

.mainPageLeftPaneBG {
    background: white;
}
@media (max-width: 768px) {
    .containerBody {
        flex-basis: 90%;
        width: 90%;
    }
    .mainPageLeftPaneBG {
        flex-basis: 10%;
        width: 10%;
    }
}
@media (max-width: 660px) {
    .containerBody {
        flex-basis: 88%;
        width: 88%;
    }
    .mainPageLeftPaneBG {
        flex-basis: 12%;
        width: 12%;
    }
}
@media (max-width: 550px) {
    .containerBody {
        flex-basis: 85%;
        width: 85%;
    }
    .mainPageLeftPaneBG {
        flex-basis: 15%;
        width: 15%;
    }
}
@media (max-width: 800px) {
    .home-container {
        flex-direction: column;
    }
    .home-column {
        flex-basis: 100% !important;
        margin: 4px 20px 16px 16px;
        max-width: 100% !important;
        max-height: 50% !important;
    }
}
.dbOverflowHeight {
    overflow-y: hidden;
    height: 100vh;
}

.unityCentralLOGO {
    /* position: absolute; */
    left: 15%;
    right: 0;
    top: 3%;
    bottom: 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #404040;
    height: 10vh;
    margin-top: 15px;
}

.mainPageLeftPaneListItem {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
}

.list-group-item,
.list-group-item-action {
    color: #fff !important;
}

.mainPageLogoutText {
    /* logOut */
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #2196f3;
}

.mainPageLogoutButton {
    /* Logout Button */
    background: #e3f2fd;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    margin-top: 10px;
}

.flexCol {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0px;
}

.noBorder {
    border: none !important;
}

.activePageLeftTitle {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: row;
    margin-left: 25px;
}

.boxShaddow {
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.loginPageFont {
    font-family: "Montserrat";
    font-size: 48px;
}

.loginPageTextBox {
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    border-radius: 10px;
    height: 40px;
    background: #ffffff;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
    width: 20rem;
}

.loginPageFooter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    background: #fbfbfb;
    padding: 10px;
    position: sticky;
    bottom: 0;
}

.loginPageBorder {
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.loginPageLoginButton {
    background: #6fcf97;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
        0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 100px;
}

.loginPageButton {
    width: 200px;
    letter-spacing: 1.25px;
}

::placeholder {
    color: #aaa3a3;
    opacity: 1;
}

.inp_TextBoxRow {
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ffffff;
    width: 100%;
    height: 3.5rem;
}

.inp_TextBox {
    border: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
    padding-left: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    outline: none;
}

.inp_TextBox:-webkit-autofill,
.inp_TextBox:-webkit-autofill:hover,
.inp_TextBox:-webkit-autofill:focus,
.inp_TextBox:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.PhoneInputInput:-webkit-autofill,
.PhoneInputInput:-webkit-autofill:hover,
.PhoneInputInput:-webkit-autofill:focus,
.PhoneInputInput:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.messageBody {
    width: 80%;
    margin-top: 2rem;
    border: none;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    font-family: "Montserrat";
    padding: 10px;
}

.plansBtn {
    margin-top: 2rem;
    border: none;
    height: 4rem;
}

.forgotPassSpans {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.1px;
}

/* plans page */

.leftCard {
    display: flex;
    justify-content: flex-end;
}

.rightCard {
    display: flex;
    justify-content: flex-start;
}

.signUpBorder {
    border: none;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(152, 149, 149, 0.25);
}

.signUpTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #2d3748;
    /* margin: 10px 20px; */
}

.signUpText {
    margin-top: 40px;
}

.titleRow {
    font-weight: 400 !important;
    /* margin-bottom: 4% !important;
    margin-top: 4% !important; */
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 4.5rem;
}

.plansPricing {
    margin-top: 5rem;
    margin-bottom: 4rem;
}

.plansListItem {
    margin-top: 1rem;
}

.plansDesc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #4a5568;
    padding-left: 8%;
    padding-right: 8%;
    margin-bottom: 3rem;
}

.signUpPricing {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3rem;
    color: #000000;
}

.signUpList {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.signUpButton {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d3748;
    background: #edf2f7;
    width: 100%;
    margin-top: 2rem;
    border: none;
    height: 4rem;
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    position: absolute;
    right: 7px;
    top: calc(50% - 0.5em);
    pointer-events: none;
    color: rgba(0, 0, 0, 0.54);
}
.signUpButton:focus .signUpButton:active {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #e3f2fd;
    background: #0798ea;
    height: 50px;
    width: 100%;
}

.billingHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 1.9rem;
    line-height: 2.9rem;
    color: #2d3748;
    margin-left: 2rem;
}

.loginTextBoxSmall {
    padding-left: 10px;
    width: 100%;
    height: 3rem;
    margin: 0px;
}

.loginTextBoxLarge {
    padding-left: 10px !important;
    width: 100%;
    height: 3rem;
}

.confirmMailLabel {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
    /* or 87% */
    letter-spacing: 0.1px;
    /* Black / High Emphasis */
    color: rgba(0, 0, 0, 0.87);
}

.infoFeedItem {
    background: #e3f2fd;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #000000;
    height: 50px;
    width: 100%;
    margin-top: 5px;
}

.infoFeedItemBorder {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
}

.infoFeedInnerItem {
    display: flex;
    justify-content: center;
    align-items: center;
}

.paddingLeft {
    padding-left: 10px;
}

.marginLeft {
    margin-left: 100px;
}

.centerInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.alertsFullScreen {
    margin: 26px 0px;
    margin-left: 110px;
    margin-top: 5px;
}

.bw_btn {
    height: 2.5rem;
    min-width: 5rem;
    background-color: #2196f3 !important;
    box-shadow: 0 1px 10px rgb(0 0 0 / 20%), 0 4px 5px rgb(0 0 0 / 12%),
        0 2px 4px rgb(0 0 0 / 14%);
    color: #fff !important;
    border-radius: 10px !important;
    font-size: 1rem !important;
    border: none !important;
    justify-content: center !important;
    align-items: center !important;
    vertical-align: middle !important;
}

.bw_plusButton {
    cursor: pointer;
    font-size: 1.5rem;
    color: #404040;
    line-height: 24px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    margin-right: 3px;
    height: 18px;
    width: 18px;
}

.home_alert_body_row {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 2rem;
}

.homeContactView {
    background: #fbfbfb;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 10px;
    width: 100% !important;
    height: fit-content;
    max-height: 25rem;
    overflow: auto;
    margin-bottom: 25px;
}

.marginZero {
    margin: 0px;
}

.updateSpaceName {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 431px;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    height: 40px;
    border-radius: 10px;
    box-shadow: none;
}

input#newFolderName,
#spacename,
#updateSpacename,
#addNewWorkspaceAlert,
#workspacename {
    margin-left: "38px";
    width: 431px;
    /* border: 1px solid #b8b8b8; */
    box-sizing: border-box;
    /* height: 40px; */
    border-radius: 10px;
    box-shadow: none;
    background-color: none;
}
#editAlert {
    margin-left: "21px" !important;
}
input#addNewWorkspaceAlert {
    margin-left: "-21px" !important;
}

.chatTextBox {
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    height: 3rem;
    padding-left: 10px;
    outline: none;
}

.updateSpaceNameTitle.addFolder.ShareFile {
    margin-bottom: 0px;
}

.updateSpaceNameTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    padding-bottom: 25px;
}
#div_lower_menu {
    top: -19px !important;
    position: absolute !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between !important;
}
.updateSpaceNameTitle.addFolder {
    margin-top: -29px;
    margin-left: -291px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #404040;
}

.list-group-item {
    color: #000000 !important;
}

.WorkspaceModal.addspace {
    margin-top: -124px;
    margin-right: -103px;
    margin-bottom: 21px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #404040;
}

.addWorkspaceModal.addspace {
    margin-left: -236px;
    /* margin-left: -234px; */
    margin-top: -29px;
    margin-bottom: 21px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #404040;
}

.addWorkspaceModal.addwork {
    margin-left: -194px;
    margin-top: -29px;
    margin-bottom: 27px;
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 35px;
    color: #000000;
}

.addWorkspaceModalImg {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
}

.wsWrapper {
    overflow-x: auto;
    white-space: nowrap;
}

.wsListOF {
    overflow-y: hidden;
    height: 30vh;
}

.wsListOF:hover {
    overflow-y: scroll;
}

.rippleBtnFont,
.ctBtnFont {
    font-family: "Montserrat" !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    letter-spacing: 0.1px !important;
    text-align: left;
    text-transform: none !important;
}

.ctLabel {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    width: 360px;
    text-align: end;
    white-space: nowrap;
    margin-right: 10px;
}

.ctPopupLabel {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    width: 360px;
    text-align: end;
    white-space: nowrap;
    margin-right: 10px;
}

.ctHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 37px;
    color: #2d3748;
}

.ctMainButton {
    height: 30px;
    width: 200px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #2196f3 !important;
    background: #e3f2fd !important;
    box-shadow: 0 2px 2px rgb(0 0 0 / 24%);
    border-radius: 4px !important;
    margin: 8px 0px 8px 8px !important;
}

#main div {
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
}

.ctButton {
    height: 30px;
    width: 100px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #2196f3 !important;
    background: #e3f2fd !important;
    box-shadow: 0 2px 2px rgb(0 0 0 / 24%);
    border-radius: 4px !important;
    margin: 8px 0px 8px 8px !important;
}

.nav-link.active {
    font-family: "Montserrat";
    font-style: normal;
    color: #000000 !important;
    background: #e3f2fd !important;
}

.nav-link {
    font-weight: 600;
    font-size: 14px;
    font-family: "Montserrat";
    font-style: normal;
    color: #000000 !important;
}

.directionCol {
    flex-direction: column;
}

.grow {
    transition: all 0.2s ease-in-out;
}

.grow:hover {
    transform: scale(1.1);
}

.circleShapeColor {
    background: radial-gradient(
            96% 96% at 70.4% 31.2%,
            #838fff 0%,
            rgba(227, 230, 255, 0) 100%
        ),
        #030092;
    /* 3242222 */
    box-shadow: 0px 40px 80px rgba(35, 0, 248, 0.24),
        inset 0px 2px 10px rgba(255, 255, 255, 0.58),
        inset 0px -20px 50px rgba(124, 161, 255, 0.37),
        inset 10px 16px 20px rgba(210, 165, 255, 0.95);
}

.MuiAutocomplete-popper {
    z-index: 22222222 !important;
}

.modalTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 0.15px;
    color: #000000;
    margin-top: 5rem;
}

#inviteFromLogin
    .MuiInput-underline
    .MuiInput-underline:before
    .MuiInput-underline:after {
    border: none !important;
    border-bottom: none;
}

.popupBtn {
    width: 17rem !important;
}

/* #menu_Department .MuiPopover-paper {
    width: 29% !important;
} */

@media only screen and (max-width: 1080px) {
    .res-1 {
        margin-left: 5px;
    }
}

/* titlebar */

.headerSearchBox {
    background: #ffffff;
    box-shadow: 0px 0px 1px rgb(0 0 0 / 48%), 0px 0px 4px rgb(0 0 0 / 12%);
    border-radius: 10px;
    width: 100%;
}

.headerSearchBtn {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    letter-spacing: 0.44px !important;
    color: #ffffff !important;
    background: #00d1ff !important;
    border-radius: 99px !important;
}

.dlaResetButton {
    height: 40px;
    width: 200px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #2196f3 !important;
    background: #e3f2fd !important;
    box-shadow: 0 2px 2px rgb(0 0 0 / 24%);
    border-radius: 4px !important;
    margin: 8px 0px 8px 8px !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: green !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: green !important;
}

.breadcrumbWrapper {
    height: 15vh;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.mainBreadcrumb {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 27px;
    /* color: #212121 !important; */
    font-weight: 700;
    max-width: 500px !important;
}

.mainBreadcrumb:hover {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 27px;
    /* color: #0e7adf !important; */
    font-weight: 700;
    max-width: 500px !important;
}

.subBreadcrumb {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* color: #212121 !important; */
    padding-top: 20px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.subBreadcrumb:hover {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* color: #095ac4 !important; */
    padding-top: 20px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.breadcrumb {
    font-family: "Montserrat";
    font-size: 12px;
    color: rgba(64, 64, 64, 1);
}

.tableRowClass {
    height: 45px;
    font-family: "Montserrat" !important;
}

.titleBarSearchDiv {
    width: auto;
    flex-grow: 1;
}

.titleBarMainTitle {
    height: 50px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #404040;
    white-space: pre;
}

.titleBarSearchInput {
    border: none !important;
    height: 30px !important;
}
.MuiBackdrop-root.MuiModal-backdrop.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop,
.MuiBackdrop-root.MuiModal-backdrop.css-919eu4 {
    background: none !important;
}
.MuiBox-root.css-1wnsr1i,
.MuiBox-root.css-1wnsr1i {
    border: none !important;
}
.titleBarParentDiv {
    background: #fff;
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 48%);
    height: 40px;
}

.workspaceIconRow {
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
}
.stared-value {
    max-height: 21vh;
}
.info-text {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    width: 336px;
    height: 24px;
    padding: 9px 12px 4px 8px;
    text-align: center;
}
.info_btn {
    width: 99px;
    height: 33px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    color: black;
    background: white;
    border: 1px solid;
    border-radius: 12px;
}
.modal-background {
    background: blue;
}
.cursorPointer {
    cursor: pointer !important;
}
.notAllowed {
    color: rgb(117, 111, 111);
    pointer-events: none;
}

.cursorDefault {
    cursor: default;
}

.cursorNotAllowed {
    cursor: not-allowed !important;
}

.spacelistTable {
    padding-top: 0px !important;
    height: 55vh;
    overflow: auto;
}

.spacelistTableRow {
    height: 40px !important;
}

.spaceListInfoWrapper {
    width: 100%;
    height: 70vh;
    padding-top: 30px;
}

.spaceListInfoSection {
    box-shadow: 5px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    width: 90%;
    background-color: #ffffff;
    height: 50vh;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.spaceListInfoHrLine {
    width: 100%;
    border: 0.5px solid rgba(185, 185, 185, 0.5);
    margin: 0.5rem 0px;
}

.spacelistInfoTitle {
    font-style: normal;
    margin-left: 100px;
    padding-top: 18px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center !important;
    font-weight: 700;
}

.spacelistInfoHeader {
    padding: 0.9rem 0px 0px;
    font-weight: 600;
    text-overflow: ellipsis !important;
    white-space: inherit !important;
    overflow: hidden;
    width: 200px;
}

.spacelistInfoRow {
    padding: 0.3rem 0px 0px;
}

.spaceCardGridWrapper {
    height: 60vh;
    border-radius: 10px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.spaceCardGrid {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    width: 90%;
    box-sizing: border-box;
    border-radius: 10px !important;
    height: 27vh !important;
}

.spaceCardGridBodyWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: "Montserrat";
    overflow-wrap: anywhere;
    overflow: auto;

    margin-top: 10px;
    cursor: pointer;
}

.spaceCardGridBodyText {
    overflow: hidden;
    text-align: center;
    margin: 0;
    white-space: pre;
    /* display: flex !important; */
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
    text-overflow: ellipsis !important;
    white-space: break-spaces !important;
    width: 100px !important;
    font-size: 15px !important;
}

.tableListRow {
    font-family: "Montserrat" !important;
    background-color: #fff;
}

.tableListName {
    text-overflow: ellipsis !important;
    white-space: inherit !important;
    overflow: hidden;
}

.tableListName:hover {
    text-decoration: underline;
    color: #0798ea;
}

.tableListCheckCol {
    max-width: 20px !important;
    width: 20px !important;
}

.tableListSelectedRow {
    background: rgba(197, 244, 255, 1);
}

.tableListSelectedRow:hover {
    background: rgba(197, 244, 255, 1) !important;
    cursor: default;
}

.background {
    background-color: #fbfbfb;
}

.mainBreadcrumb {
    color: #ea3c07;
}
.MuiTableCell-root {
    font-family: "Montserrat" !important;
}

span.NavItemPadding.mainPageIconNewFont.textEllipsis.workspaceList {
    margin-top: 5px;
}

span.NavItemPadding.mainPageIconNewFont.textEllipsis {
    margin-top: 3px;
}

.contentPaneBottom {
    height: 100vh !important;
}

.MuiBreadcrumbs-separator {
    padding-top: 5px;
    font-size: 26px;
}

.workspaceIcon {
    height: 26px;
    width: 26px;
}

.spaceIcon {
    height: 23px;
    width: 23px;
}

.folderIcon {
    height: 20px;
    width: 23px;
}
div#subMenu {
    margin-left: 100px !important;
    height: 100vh;
}
.titleSection {
    height: 5vh !important;
}
.statsBar {
    flex-basis: 50%;
    height: 21%;
}
/* 50% */

@media (min-width: 2733px) and (max-width: 4102px) {
    /* div#subMenu {
        margin-left: 2.8% !important;
    } */
    .statsBar {
        height: 48%;
    }
    #div_lower_menu {
        top: 402px !important;
    }
}
/* 33% */
@media (min-width: 4103px) and (max-width: 5464px) {
    /* div#subMenu {
        margin-left: 2.5% !important;
    } */
    .statsBar {
        height: 33% !important;
    }
    .titleSection {
        height: 6vh !important;
    }
    .WrkspaceClass {
        height: 6vh !important;
    }

    #div_lower_menu {
        top: 970px !important;
    }
}
/* 67 */
@media (max-width: 2320px) {
    /* div#subMenu {
        margin-left: 3.9% !important;
    } */
    .statsBar {
        height: 62% !important;
    }
    .titleSection {
        height: 18vh !important;
    }
    #div_lower_menu {
        top: 19px !important;
    }
}
/* 75 */
@media (max-width: 2120px) {
    /* div#subMenu {
        margin-left: 4.3% !important;
    } */
    .statsBar {
        height: 66% !important;
    }
    .titleSection {
        height: 18vh !important;
    }
    #div_lower_menu {
        top: -55px !important;
    }
} /*80 */
@media (max-width: 1920px) {
    .dashboard_chart-items {
        width: 553px;
    }
    /* div#subMenu {
        margin-left: 4.4% !important;
    } */
    .statsBar {
        height: 62% !important;
    }
    .titleSection {
        height: 18vh !important;
    }
    #div_lower_menu {
        top: -42px !important;
    }
}
/* 90 */
@media (max-width: 1720px) {
    /* div#subMenu {
        margin-left: 4.8% !important;
    } */
    .statsBar {
        height: 53% !important;
    }
    .titleSection {
        height: 22vh !important;
    }
}
/* 90 */
@media (max-width: 1620px) {
    /* div#subMenu {
        margin-left: 5.6% !important;
    } */
    .statsBar {
        height: 50% !important;
    }
    .titleSection {
        height: 22vh !important;
    }
}
/* 110 */
@media (min-width: 1097px) and (max-width: 1240px) {
    .alert-time {
        width: 9em;
    }
}
@media (max-width: 1500px) {
    /* div#subMenu {
        margin-left: 5.6% !important;
    } */

    .statsBar {
        height: 45% !important;
    }
    .titleSection {
        height: 24vh !important;
    }
    #div_lower_menu {
        top: -59px !important;
    }
}
/* 125 */
@media (max-width: 1280px) {
    /* div#subMenu {
        margin-left: 6.2% !important;
    } */

    .statsBar {
        height: 39% !important;
    }
    .titleSection {
        height: 24vh !important;
    }
    #div_lower_menu {
        top: -49px !important;
    }
}
/* 150 */
@media (max-width: 1097px) {
    /* div#subMenu {
        margin-left: 7.4% !important;
    } */
    .alert-time {
        width: 5em;
    }
    .statsBar {
        height: 23% !important;
    }
    .titleSection {
        height: 29vh !important;
    }
    #div_lower_menu {
        top: -44px !important;
    }
}
/* 175 */
@media (max-width: 960px) {
    /* div#subMenu {
        margin-left: 8.5% !important;
    } */
    .statsBar {
        height: 14% !important;
    }
    .titleSection {
        height: 34vh !important;
    }
    #div_lower_menu {
        top: -63px !important;
    }
}
/* 200 */
@media (max-width: 768px) {
    /* div#subMenu {
        margin-left: 11% !important;
    } */
    .statsBar {
        height: 19% !important;
    }
    .titleSection {
        height: 45vh !important;
    }
    #div_lower_menu {
        top: -56px !important;
    }
}

@media screen and (width: 1440px) {
    #email-alert-table {
        height: 340px;
    }
}

@media screen and (width: 1920px) {
    #email-alert-table {
        height: 580px;
    }
}

.react-datetime-picker__clock {
    display: none;
}
.react-datetime-picker__wrapper {
    border-radius: 10px;
    padding: 6px;
}
.react-datetime-picker__button {
    display: none;
}
