h6 {
    font-family: "Montserrat";
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left !important;
}

.flow-heading {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -15px;
}

.star {
    margin-left: 180px;
}

/* Define media queries for different screen sizes */
@media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    .MuiBox-root.css-1wnsr1i {
        width: 90%;
        /* Example adjustment */
        margin-left: 5%;
        /* Example adjustment */
        /* Add other necessary adjustments */
    }
}

/* For 33% width */
@media (min-width: 768px) and (max-width: 1023px) {
    .MuiBox-root.css-1wnsr1i {
        width: 33%;
        margin-left: 33%;
        /* Other necessary adjustments */
    }
}

/* For 50% width */
@media (min-width: 1024px) and (max-width: 1279px) {
    .MuiBox-root.css-1wnsr1i {
        width: 50%;
        margin-left: 25%;
        /* Other necessary adjustments */
    }
}

/* For 67% width */
@media (min-width: 1280px) and (max-width: 1535px) {
    .MuiBox-root.css-1wnsr1i {
        width: 67%;
        margin-left: 16.5%;
        /* Other necessary adjustments */
    }
}

/* For 80% width */
@media (min-width: 1536px) and (max-width: 1791px) {
    .MuiBox-root.css-1wnsr1i {
        width: 80%;
        margin-left: 10%;
        /* Other necessary adjustments */
    }
}

@media (max-width: 950px) {
    .star {
        margin-left: 140px;
    }
}

@media (min-width: 2733px) and (max-width: 4102px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 45% !important;
    }
}

@media (min-width: 2050px) and (max-width: 2743px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 42% !important;
    }
}

@media (min-width: 1300px) and (max-width: 1370px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 34% !important;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 35% !important;
    }
}

@media (min-width: 1850px) and (max-width: 2050px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 39% !important;
    }
}

@media (min-width: 1720px) and (max-width: 1850px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 38% !important;
    }
}

@media (min-width: 1520px) and (max-width: 1720px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 37% !important;
    }
}

@media (max-width: 850px) {
    .star {
        margin-left: 120px;
    }
}

@media (max-width: 1052px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 26% !important;
    }
}

@media (max-width: 1800px) and (min-width: 2732px) {
    .MuiBox-root.css-1wnsr1i {
        margin-left: 45% !important;
    }
}

.member {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.members-inside {
    height: 45px;
    align-items: center;
    display: flex;
}

.MuiBox-root.css-1wnsr1i {
    padding: 0px 12px 13px 35px;
    width: 441px;
    margin-left: 33%;
    height: 100vh;
}

.about {
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.about_inside {
    font-family: "Montserrat";
    font-weight: 700;
    letter-spacing: 0em;
    margin-left: -14px !important;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
}

.membersP {
    padding: 12px;
    color: #0071ff;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 81px;
    padding-bottom: 5px;
}

.attribute-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.attribute-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.attribute-heading {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
}
.attribute-icons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
