.settingsGeneralLabel {
    text-align: right !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* margin-right: 310px; */
    line-height: 30px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    padding-top: 5px;
    white-space: nowrap;
}

.settingsContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
}

#generalSettingsButton {
    border: none !important;
    text-transform: uppercase;
    border-radius: 10px !important;
    border-radius: 4px !important;
    margin-bottom: 80px !important;
    margin-top: 20px !important;
    height: 30px;
    width: 200px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff !important;
    min-width: 8rem;
    height: 38px;
    color: #fff !important;
    /* margin-left: 400px; */
    background: rgb(7, 152, 234);
    text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
    .settingsGeneralLabel {
        text-align: left !important;
    }
}

@media only screen and (max-width: 850px) {
    .settingsContainer {
        width: 95%;
    }
}

.settingInput {
    height: 3rem !important;
}